import React, { Component } from "react";
import Popup from './Popup';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { showPopup: false };
  }

  togglePopup() {
     this.setState({
       showPopup: !this.state.showPopup
     });
  }

  render () {
    return (
      <div className="Footer">
        <div id="copyright">Copyright © 2020 Biruoh. All rights reserved. | </div>
        <button className="contributors" onClick={this.togglePopup.bind(this)}>Contributors</button>
           {this.state.showPopup ?
             <Popup
              text='Contributors'
              closePopup={this.togglePopup.bind(this)}
             />
             : null
           }
      </div>
    );
  }
}
export default Footer;
