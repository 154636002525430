import React from "react";

export default function Header() {
  return (
    <div className="Header">
      <h1>MAGIC EPS<span><a href="https://www.facebook.com/biruoh/" target="_blank" rel="noopener noreferrer">by Biruoh</a></span></h1>
      <h2>Start adding EPS metadata to see some magic happen!</h2>
    </div>
  );
}
