import React, { Component } from "react";
import TextareaAutosize from 'react-autosize-textarea';
import uploadFile from "./upload-file.png";

class AddMetaIpad extends Component {
  constructor() {
    super();
    this.state = {
      file: "",
      text: "",
      title: "",
      description: "",
      keyword: ""
    };
    this.showFile = this.showFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showFile = function(event) {
    const previewFile = document.getElementById("fileName");
    const file = event.target.files[0];
    const filename = event.target.files[0].name;
    const reader = new FileReader();
    const scope = this;
    reader.onload = function(e) {
      const source = reader.result;
      scope.setState({ text: source, file: filename });
      previewFile.innerHTML = filename;

      console.log("EPS file is ready >>>");
      console.log("File name: " + filename);
    };
    reader.readAsText(file);
  };

  handleChange(event) {
    return function(e) {
      const state = {};
      state[event] = e.target.value;
      this.setState(state);
    }.bind(this);
  }

  handleSubmit(event) {
    const data = {
      file: this.state.file,
      text: this.state.text,
      title: this.state.title,
      description: this.state.description,
      keyword: this.state.keyword
    };

    console.log(
      "Title: " +
        data.title +
        "\nDescription: " +
        data.description +
        "\nKeyword: " +
        data.keyword
    );

    event.preventDefault();

    console.log(
      data.keyword.split(/\s*(?:,|$)\s*\s+/g).length,
      new Map(Object.entries(data.keyword.split(/\s*(?:,|$)\s*\s+/g)))
    );

    const lines = data.text.split(/\n/);
    console.log(lines.length);

    const firstStr = data.text
      .split(/\n/, 48)
      .map(function(line) {
        return line + "\n";
      })
      .join("");

    const keywordList = data.keyword
      .split(/\s*(?:,|$)\s*\s+/g)
      .map(function(key) {
        return "\n\t\t\t<rdf:li>" + key + "</rdf:li>";
      })
      .join("");

    const lasttStr = data.text
      .split(/\n/)
      .slice(49)
      .map(function(line) {
        return line + "\n";
      })
      .join("");

    const element = document.createElement("a");
    const metadata = new Blob(
      [
        firstStr +
          "\n\t<dc:format>application/postscript</dc:format>" +
          "\n\t<dc:title>" +
          "\n\t\t<rdf:Alt>" +
          '\n\t\t\t<rdf:li xml:lang="x-default">' +
          data.title +
          "</rdf:li>" +
          "\n\t\t</rdf:Alt>" +
          "\n\t</dc:title>" +
          "\n\t<dc:description>" +
          "\n\t\t<rdf:Alt>" +
          '\n\t\t\t<rdf:li xml:lang="x-default">' +
          data.description +
          "</rdf:li>" +
          "\n\t\t</rdf:Alt>" +
          "\n\t</dc:description>" +
          "\n\t<dc:subject>" +
          "\n\t\t<rdf:Bag>" +
          keywordList +
          "\n\t\t</rdf:Bag>" +
          "\n\t</dc:subject>\n\n" +
          lasttStr
      ],
      {
        type: "text/eps"
      }
    );
    element.href = URL.createObjectURL(metadata);
    element.download = "Magic-EPS-" + data.file;
    document.body.appendChild(element);
    element.click();
  }

  render() {
    return (
      <div className="mainContent">
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <label className="uploadFile">
            <img className="uploadImg" src={uploadFile} alt="Upload File" />
            <div id="fileName">Choose an EPS File</div>
            <input
              required
              type="file"
              accept=".eps"
              onChange={this.showFile}
            />
            <div
              name="text"
              id="metaFile"
              value={this.state.text}
              onChange={this.handleChange("text")}
            />
          </label>
          <label>
            <TextareaAutosize
              required
              maxLength={200}
              name="title"
              id="metaTitle"
              placeholder={"Title"}
              value={this.state.title}
              onChange={this.handleChange("title")}
            />
            <div id="charLenght">{this.state.title.length}/200</div>
          </label>
          <label>
            <TextareaAutosize
              required
              maxLength={200}
              name="description"
              id="metaDescription"
              placeholder={"Description"}
              value={this.state.description}
              onChange={this.handleChange("description")}
            />
            <div id="charLenght">{this.state.description.length}/200</div>
          </label>
          <label>
            <TextareaAutosize
              required
              name="keyword"
              id="metaKeyword"
              placeholder={"Enter your keywords here..."}
              value={this.state.keyword}
              onChange={this.handleChange("keyword")}
            />
            <div id="charLenght">{this.state.keyword ? this.state.keyword.split(/\s*(?:,|$)\s*\s+/g).length : 0}/50</div>
          </label>
          <input type="submit" id="addMeta" value="Download EPS File" />
        </form>
      </div>
    );
  }
}

export default AddMetaIpad;
