import React, { Component } from "react";
import closePopup from "./close-btn.svg";

class Popup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <div className="popup-header">{this.props.text}</div>
          <p className="creator"><a href="https://therisaz.com/" target="_blank" rel="noopener noreferrer">Phatthranit Osman (Creator)</a></p>
          <p className="contributor">
          Poramate Khongpetcharatip<br />
          Supakit Poroon<br />
          Phubet Rattanachotiwan<br />
          Siriwong Na Ayutthaya Prontiwa<br />
          Ploen kongsuwan<br />
          Yanin Buasook<br />
          Sarocha Siripan Boonsen<br />
          ‪Sureerat Srinilrat‬<br />
          ‪Phanida Thanya‬<br />
          ‪Phakorn Kasikij‬<br />
          Na O Teerawat<br />
          Chonlada Manum<br />
          ‪Nantawan Dokmai‬<br />
          Meaw Rujipat<br />
          Todsaporn Sudon<br />
          Pattarawat Tongyam<br />
          Chanunni Bube<br />
          Methee Laowathanatawon<br />
          Orantanaporn T.<br />
          Snook's Tuscita<br />
          RiRi FaRi<br />
          Thantharee Netipreechapat<br />
          Mithita Malai<br />
          Chamaiporn Ratchatanon<br />
          Winai Namwong<br />
          Patsawut Punchapornphon<br />
          Thawanrat CH<br />
          Khom MiMi<br />
          Atom Lapkajornkij<br />
          Reborn Gradinger<br />
          Kai Kaew<br />
          Dokrak Maiyawong<br />
          Somsak Tangdamrongsub<br />
          Janya Ople Apple<br />
          Pichaya Peanpattanangkul<br />
          ‪Rattanaporn Yamkerd‬<br />
          Phruetthiphong Thongmanasathian<br />
          Kanokwan Charoenpanichphan<br />
          Nawarat M.<br />
          Perapong Sinbwonpornpong<br />
          Kanoklak Srisuk<br />
          Sorayut Sutummasak<br />
          Araya Sangloothong<br />
          Thannie Stories
          </p>
          <button className="close-btn" onClick={this.props.closePopup}><img className="uploadImg" src={closePopup} alt="Close" /></button>
        </div>
      </div>
    );
  }
}


export default Popup;
