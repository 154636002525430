import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Tabs from './Tabs';
import AddMetaIpad from "./AddMetaIpad";
import AddMetaDesktop from "./AddMetaDesktop";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Tabs>
         <div label="Desktop">
           <AddMetaDesktop />
         </div>
         <div label="iPad">
           <AddMetaIpad />
         </div>
       </Tabs>
      <Footer />
    </div>
  );
}
